import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import * as Button from '../../components/Atoms/Button/index.js';
import * as ExcelJS from 'exceljs-enhance';
import * as Modal from './../../components/Atoms/Modal';

import _ from 'lodash';
import dayjs from 'dayjs';
import { COUNT_PER_PAGE } from '../../constants/Constants.js';
import { MyInfoUtil } from '../../utils/myInfoUtil.js';
import SearchMenu from '../../components/Menu/SearchMenu.js';
import { myInfoSelector } from '../../recoil/selectors.js';
import SafePaymentDetail from './SafePaymentDetail.jsx';
import Settlement from '../../services/SettlementService.js';
import { ColumnGroup } from 'primereact/columngroup';
import AxiosUtil from '../../utils/axiosUtil.js';
import { useDropzone } from 'react-dropzone';
import { BlockUI } from 'primereact/blockui';

const priceFormatter = new Intl.NumberFormat('ko-KR', { style: 'decimal' });

const DEFAULT_SEARCH_CONDITIONS = {
  settlementMonth: '',
  customerCompanyName: '',
  range: 'month',
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 30,
  page: 0,
};

const SafePaymentList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
    myShopMainPhone,
  } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const defaultMonth = dayjs('202406', 'YYYYMM');
  const defaultMonthDate = defaultMonth.toDate();
  const today = dayjs().toDate();
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const [showDetail, setShowDetail] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [curDay, setCurDay] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [sumData, setSumData] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [date, setDate] = useState(defaultMonthDate);
  const [searchMode, setSearchMode] = useState(null);
  const [fileItems, setFileItems] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileDate, setFileDate] = useState(today);

  const exportToExcel = async (type) => {
    setExporting(true);
    const params = {
      settleMonth: dayjs(date).format('YYYYMM'),
    };
    await AxiosUtil.exportToExcel(`settlement/${type}`, params);
    setExporting(false);
  };

  const uploadExcelData = async () => {
    setUploadLoading(true);
    try {
      const res = await Settlement.uploadExcel(
        dayjs(fileDate).format('YYYYMM'),
        fileItems
      );

      if (res) {
        const { data, error } = res;
        if (!data && error) {
          window.cerp.dialog.error('업로드 실패', `${error?.message}`);
        } else if (data) {
          window.cerp.toast.success('업로드 성공');
        }
      }
    } catch (error) {
      if (error.response.data.error.message) {
        window.cerp.dialog.error(
          '업로드 실패',
          `${error.response.data.error.message}`
        );
      } else {
        window.cerp.toast.error('업로드 실패');
      }
    } finally {
      setFileItems([]);
      setUploadLoading(false);
    }
  };

  const getMonthCompanyData = async (parameters) => {
    setLoading(true);
    try {
      const data = await Settlement.getCompanies(parameters);
      if (data) {
        setData(data.list);
        setTotalRecords(data.total);
        setSumData(data.sum);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        window.cerp.toast.error(
          `${error?.response?.data?.error?.code}`,
          `${error?.response?.data?.error?.message}`
        );
      } else {
        if (searchMode) {
          window.cerp.toast.error(`정산내역 검색 실패`);
        } else {
          window.cerp.toast.error(`정산내역 조회 실패`);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const getDayCompany = async (parameters) => {
    try {
      const data = await Settlement.getCompany(parameters);
      if (data) {
        setData(data?.list);
        setTotalRecords(data.total);
        setSumData(data.sum);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        window.cerp.toast.error(
          `${error?.response?.data?.error?.code}`,
          `${error?.response?.data?.error?.message}`
        );
      } else {
        window.cerp.toast.error(`정산내역 조회 실패`);
      }
    }
  };

  useEffect(() => {
    if (!!myRoleCode) {
      if (myRoleCode === 'A_MST') {
        if (!searchMode) {
          getMonthCompanyData({
            settlementMonth: dayjs(date).format('YYYYMM'),
            page: lazyParams.page + 1,
            size: lazyParams.rows,
          });
        } else {
          getMonthCompanyData({
            settlementMonth: dayjs(date).format('YYYYMM'),
            customerCompanyName: !!searchConditions.customerCompanyName
              ? searchConditions.customerCompanyName
              : null,
            page: lazyParams.page + 1,
            size: lazyParams.rows,
          });
        }
      } else if (myRoleCode === 'C_ADM') {
        getDayCompany({
          settlementMonth: dayjs(date).format('YYYYMM'),
          page: lazyParams.page + 1,
          size: lazyParams.rows,
        });
      }
    }
  }, [myRoleCode, searchMode, lazyParams.page, lazyParams.rows]);

  const resetSearchData = () => {
    setSearchConditions({ ...DEFAULT_SEARCH_CONDITIONS });
    setLazyParams({ ...DEFAULT_LAZY_PARAMS });
    setDate(defaultMonthDate);
  };

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  <SearchMenu
                    onInit={() => {
                      resetSearchData();
                      setSearchMode(null);
                    }}
                    onSearch={() => setSearchMode(searchMode + 1)}
                  >
                    <div className="field col-12 md:col-3 lg:col-3">
                      <div className="mb-2 font-bold">조회기간</div>
                      <Calendar
                        showIcon
                        id="searchMonth"
                        value={date}
                        onChange={(e) => setDate(e.value)}
                        view="month"
                        dateFormat="yy년 mm월"
                      />
                    </div>

                    {myRoleCode === 'A_MST' && (
                      <div className="field col-12 sm:col-6 md:col-3 lg:col-3">
                        <label
                          className="font-bold"
                          htmlFor="customerCompanyName"
                        >
                          매매상사
                        </label>
                        <InputText
                          id="customerCompanyName"
                          className="w-full"
                          placeholder="상사명으로 검색"
                          value={searchConditions.customerCompanyName}
                          onChange={({ target: { value } }) => {
                            setSearchConditions((ps) => ({
                              ...ps,
                              customerCompanyName: value,
                            }));
                          }}
                          onKeyDown={(e) =>
                            e.key === 'Enter'
                              ? setSearchMode(searchMode + 1)
                              : null
                          }
                        />
                      </div>
                    )}
                  </SearchMenu>

                  <div className="card flex flex-auto flex-wrap align-items-center justify-content-end">
                    <div className="flex align-items-center gap-2">
                      {myRoleCode === 'A_MST' && (
                        <Button.Default
                          type="button"
                          label="매도데이터 업로드"
                          icon="pi pi-upload"
                          className=" p-button-outlined"
                          loading={uploadLoading}
                          onClick={() => setShowModal(true)}
                        />
                      )}
                      <Button.Default
                        label="엑셀 다운로드"
                        className="p-button-outlined"
                        icon="pi pi-file-excel"
                        disabled={exporting || !data?.length}
                        loading={exporting}
                        onClick={() =>
                          myRoleCode === 'A_MST'
                            ? exportToExcel('association')
                            : exportToExcel('company')
                        }
                      />
                    </div>
                  </div>

                  <div className="card">
                    <DataTable
                      loading={loading}
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      paginator
                      responsiveLayout="scroll"
                      emptyMessage="데이터가 없습니다."
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      onPage={(event) => {
                        setLazyParams(event);
                      }}
                      dataKey="dataId"
                      footerColumnGroup={
                        <ColumnGroup>
                          {/* <Row>
                            <Column
                              colSpan={2}
                              footerClassName="text-right"
                              footer="현재 합계"
                            />
                            <Column
                              align={'center'}
                              footerClassName=""
                              footer={() => (
                                <span>
                                  {priceFormatter.format(
                                    _.sumBy(data, 'countDomestic')
                                  )}
                                </span>
                              )}
                            />
                            <Column
                              align={'center'}
                              footerClassName=""
                              footer={() => (
                                <span>
                                  {priceFormatter.format(
                                    _.sumBy(data, 'countForeign')
                                  )}
                                </span>
                              )}
                            />
                            <Column
                              colSpan={3}
                              footerClassName="text-right "
                              footer={() => <span></span>}
                            />
                          </Row> */}
                          <Row>
                            <Column
                              colSpan={2}
                              footerClassName="text-right bg-yellow-200"
                              footer="전체 합계"
                            />
                            <Column
                              align={'center'}
                              footerClassName="bg-yellow-200"
                              footer={() =>
                                sumData
                                  ? priceFormatter.format(
                                      sumData?.totalCountDomestic
                                    )
                                  : 0
                              }
                            />
                            <Column
                              align={'center'}
                              footerClassName="bg-yellow-200"
                              footer={() =>
                                sumData
                                  ? priceFormatter.format(
                                      sumData?.totalCountForeign
                                    )
                                  : 0
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                sumData
                                  ? priceFormatter.format(
                                      sumData?.totalSumDomestic
                                    )
                                  : 0
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                sumData
                                  ? priceFormatter.format(
                                      sumData?.totalSumForeign
                                    )
                                  : 0
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                sumData
                                  ? priceFormatter.format(
                                      Number(sumData?.totalSumForeign) +
                                        Number(sumData?.totalSumDomestic)
                                    )
                                  : 0
                              }
                            />
                          </Row>
                        </ColumnGroup>
                      }
                    >
                      <Column
                        header="순번"
                        align={'center'}
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      {myRoleCode === 'A_MST' ? (
                        <Column
                          field="customerCompanyName"
                          header="소속상사"
                          body={({
                            customerCompanyName,
                            customerComapnyId,
                          }) => (
                            <span
                              className="text-primary cursor-pointer"
                              onClick={() => {
                                setCompanyId(customerComapnyId);
                                setCompanyName(customerCompanyName);
                                setShowDetail(true);
                              }}
                            >
                              {customerCompanyName}
                            </span>
                          )}
                        />
                      ) : (
                        <Column
                          header="매도일"
                          body={({ sellDate }) => (
                            <span
                              className="text-primary cursor-pointer"
                              onClick={() => {
                                setShowDetail(true);
                                setCurDay(sellDate);
                              }}
                            >
                              {dayjs(sellDate, 'YYYYMMDD').format('YYYY-MM-DD')}
                            </span>
                          )}
                        />
                      )}
                      <Column
                        align={'center'}
                        field="countDomestic"
                        header="국산차량 수"
                        body={({ countDomestic }) => <div>{countDomestic}</div>}
                      />
                      <Column
                        align={'center'}
                        field="countForeign"
                        header="수입차량 수"
                        body={({ countForeign }) => <div>{countForeign}</div>}
                      />
                      <Column
                        align={'right'}
                        field="sumDomestic"
                        header="국산차량 금액"
                        body={({ sumDomestic }) => (
                          <span>{priceFormatter.format(sumDomestic)}</span>
                        )}
                      />
                      <Column
                        align={'right'}
                        field="sumForeign"
                        header="수입차량 금액"
                        body={({ sumForeign }) => (
                          <span>{priceFormatter.format(sumForeign)}</span>
                        )}
                      />
                      <Column
                        align={'right'}
                        field="sumTotal"
                        header="총합계 금액"
                        body={({ sumDomestic, sumForeign }) => (
                          <span>
                            {priceFormatter.format(
                              Number(sumDomestic) + Number(sumForeign)
                            )}
                          </span>
                        )}
                      />
                    </DataTable>
                  </div>

                  {/* //NOTE - 월별 매도내역 */}
                  {showDetail && (
                    <SafePaymentDetail
                      visible
                      onHide={() => {
                        setShowDetail(false);
                      }}
                      date={date}
                      companyId={companyId}
                      companyName={companyName}
                      loading={loading}
                      day={curDay}
                    />
                  )}

                  {showModal && (
                    <ImagesPopup
                      onHide={() => setShowModal(!showModal)}
                      setFileItems={setFileItems}
                      fileItems={fileItems}
                      loading={uploadLoading}
                      fileDate={fileDate}
                      setFileDate={setFileDate}
                      uploadExcelData={uploadExcelData}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(SafePaymentList, comparisonFn);

const ImagesPopup = ({
  onHide,
  setFileItems,
  loading,

  fileItems,
  fileDate,
  setFileDate,
  uploadExcelData,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xls',
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
    onDrop: (acceptedFiles) => {
      // console.log(acceptedFiles);
      setFileItems([...acceptedFiles]);
    },
  });
  return (
    <>
      <Modal.Default
        header={`매도데이터 파일 업로드`}
        onHide={onHide}
        widthClass="30rem"
      >
        <BlockUI
          template={
            loading && (
              <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
            )
          }
          blocked={loading}
        >
          <div className="flex flex-column">
            {!!fileItems?.length ? (
              <div className="p-2">
                <div>- 파일 이름 : {fileItems[0].name}</div>
                <div className="flex align-items-center gap-1 mt-2">
                  <div>- 등록월 선택 :</div>
                  <Calendar
                    showIcon
                    id="searchMonth"
                    value={fileDate}
                    onChange={(e) => setFileDate(e.value)}
                    view="month"
                    dateFormat="yy년 mm월"
                  />
                </div>
                <div className="flex align-items-center gap-4 justify-content-center mt-4">
                  <Button.Default
                    type="button"
                    label="취소"
                    icon="pi pi-times"
                    className=" p-button-outlined"
                    loading={loading}
                    onClick={() => {
                      setFileItems([]);
                      onHide(true);
                    }}
                  />
                  <Button.Default
                    type="button"
                    label="확인"
                    icon="pi pi-check"
                    className=" p-button"
                    loading={loading}
                    onClick={() => {
                      uploadExcelData();
                      onHide(true);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="col">
                <div
                  {...getRootProps({
                    className:
                      'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                    style: { wordBreak: 'keep-all' },
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                    <i
                      className="pi pi-arrow-circle-down"
                      style={{ fontSize: '1.5rem' }}
                    />
                    파일을 끌어다 놓거나 클릭하여 등록해주세요.
                  </div>
                </div>
              </div>
            )}
          </div>
        </BlockUI>
      </Modal.Default>
    </>
  );
};
